<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">

            <div class="d-block row col-lg-8  mx-auto">
                <b-form-group>
                    <slot name="label">
                        Did the vendor specify that they do, in fact accept credit cards on the form the vendor completed?
                        <custom-tooltip
                            :content="'If No, you must obtain the ACH Banking Form from the vendor.  If you have that form, pls select yes & attach form. If you do not, cancel this request and obtain information requested.'"></custom-tooltip>
                    </slot>
                    <b-form-radio-group id="credit-card" v-model="MediaVendor.IsCreditCardAccepted" name="credit-card">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="IsCreditCardAccepted">
                <b-form-group>
                    <slot name="label">Are there fees or conditions/limitations in accepting payment via credit card for this organization? An example would be that the vendor charges a 3% fee on all transactions, or that the companies credit card information must be held on file with the vendor for immediate payment processing.
                        <custom-tooltip
                            :content="'If No, you must obtain the ACH Banking Form from the vendor.  If you have that form, please select yes & attach form. If you do not, cancel this request and obtain the information requested.'"></custom-tooltip>
                    </slot>
                    <b-form-radio-group id="conditions-limitations"
                        v-model="MediaVendor.AreThereConditionsOrLimitations" name="conditions-limitations">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="AreThereConditionsOrLimitations">
                <b-form-group>
                    <slot name="label">What are the Limitations?</slot>
                    <b-form-input autocomplete="off" id="CreditLimitation" class="omniipclass"
                        v-model="MediaVendor.CreditLimitation" placeholder="What are the Limitations?"></b-form-input>
                </b-form-group>
                <b-form-group label="Did the vendor indicate that they would like to be setup for ACH payments?"
                    class="timeonly-text">
                    <b-form-radio-group v-model="MediaVendor.HasACHPaymentSetup" id="HasACHPaymentSetup" name="HasACHPaymentSetup">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group v-if="MediaVendor.HasACHPaymentSetup == 'true'" label="Did the vendor provide a bank letter, invoice or voided check confirming their account information?"
                    class="timeonly-text">
                    <b-form-radio-group v-model="MediaVendor.HasBankLetterInvoice" id="HasBankLetterInvoice" name="HasBankLetterInvoice">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="HasBankLetterInvoice">
                <div class="fieldborder">
                    <label>Bank Name</label>
                    <b-form-input @focus="ResetDirty('BankName')" @blur="SetDirty('BankName')" autocomplete="off"
                        id="bank-name" class="omniipclass" v-model="MediaVendor.BankName" placeholder="Enter Bank Name"
                        :maxlength="99">
                    </b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.BankName.$dirty && !$v.MediaVendor.BankName.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder pt-2">
                    <label>BANK COUNTRY</label>
                    <OmniSelect @focus="ResetDirty('BankCountry')" @blur="SetDirty('BankCountry')"
                        class="pt-0 mb-0 omniipclass omniindexclass" placeholder="Select Country" name="BankCountry"
                        :showLabel="false" label="BankCountry" v-model="MediaVendor.BankCountry" :options="CountryList">
                    </OmniSelect>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.BankCountry.$dirty && !$v.MediaVendor.BankCountry.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder pt-2">
                    <label>BANK ADDRESS LINE 1 </label>
                    <b-form-input @focus="ResetDirty('BankAddressLine1')" @blur="SetDirty('BankAddressLine1')"
                        autocomplete="off" id="bankaddressline1" class="omniipclass"
                        v-model="MediaVendor.BankAddressLine1" placeholder="Enter Bank Address Line1"
                        :maxlength="16"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.BankAddressLine1.$dirty && !$v.MediaVendor.BankAddressLine1.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder pt-2">
                    <label>BANK ADDRESS LINE 2 </label>
                    <b-form-input @focus="ResetDirty('BankAddressLine2')" @blur="SetDirty('BankAddressLine2')"
                        autocomplete="off" id="bankaddressline2" class="omniipclass"
                        v-model="MediaVendor.BankAddressLine2" placeholder="Enter Bank Address Line2"
                        :maxlength="10"></b-form-input>
                </div>
                <div class="fieldborder pt-2" v-if="IsCountryUSA">
                    <label for="city" class="lbltxt">BANK CITY</label>
                    <b-form-input @focus="ResetDirty('BankCity')" @blur="SetDirty('BankCity')" autocomplete="off"
                        id="billingCity" class="omniipclass" v-model="MediaVendor.BankCity"
                        placeholder="Enter City" :maxlength="19"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendor.BankCity.$dirty && !$v.MediaVendor.BankCity.required">
                        Required field
                    </div>
                </div>
                <div class="row fieldborder pt-2" v-if="IsCountryUSA">
                    <div class="col-md-8">
                        <label for="BankState" class="lbltxt">BANK State</label>
                        <OmniSelect @blur="SetDirty('BankState')" @focus="ResetDirty('BankState')"
                            class="pt-0 omniindexclass omniindexclass" placeholder="Select State"
                            name="BankState" :showLabel="false" :showSearch="true"
                            v-model="MediaVendor.BankState" :options="StateList">
                        </OmniSelect>
                        <div class="error-message-format"
                            v-if="$v.MediaVendor.BankState.$dirty && !$v.MediaVendor.BankState.required">
                            Required field
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label v-if="IsCountryUSA" for="BankZipCode" class="lbltxt">ZIP CODE</label>

                        <b-form-input autocomplete="off" id="BankZipCode" class="" :minlength="5"
                        v-if="IsCountryUSA" :maxlength="5" v-bind:class="{
                                redtextbox: (validationtextbox || !$v.MediaVendor.BankZipCode.numeric) && $v.MediaVendor.BankZipCode.$dirty,
                                notredtextbox: !validationtextbox && $v.MediaVendor.BankZipCode.numeric,
                            }" @blur="SetDirty('BankZipCode')" @focus="ResetDirty('BankZipCode')"
                            @keyup="ValidateZipCode()" v-model="MediaVendor.BankZipCode"
                            placeholder="Enter Zip Code"></b-form-input>
                        <div v-show="validationtextbox && $v.MediaVendor.BankZipCode.$dirty" style="width:135px;"
                            class="error-message-format">
                            Input must be 5 characters
                        </div>
                        <div v-show="!$v.MediaVendor.BankZipCode.numeric && $v.MediaVendor.BankZipCode.$dirty"
                            style="width:135px;" class="error-message-format">
                            This field can only contain numerical values
                        </div>
                    </div>
                </div>
                <div class="fieldborder pt-2">
                    <label>Bank Account name</label>
                    <b-form-input autocomplete="off" id="AccountName" class="omniipclass" v-model="MediaVendor.BankAccountName" placeholder="Enter Account name"></b-form-input>
                </div>
                <div class="fieldborder pt-2">
                    <label>Bank Account number</label>
                <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '#################']"
                    style="margin-top:1px;" class="form-control" @focus="ResetDirty(MediaVendor.BankAccountNumber)"
                    @blur="SetDirty(MediaVendor.BankAccountNumber)" placeholder="Enter Account Number" autocomplete="off" id="BankAccountNumber" v-model="MediaVendor.BankAccountNumber"></the-mask>
                </div>
                <div class="fieldborder pt-2">
                    <label>Routing number</label>
                <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '#########']"
                    style="margin-top:1px;" class="form-control" @focus="ResetDirty(MediaVendor.BankRoutingNumber)"
                    @blur="SetDirty(MediaVendor.BankRoutingNumber)" placeholder="Enter Routing Number" autocomplete="off" id="BankRoutingNumber" v-model="MediaVendor.BankRoutingNumber"></the-mask>
                </div>
                <div>
                    <label>Remittance Advice Email</label>
                    <b-form-input autocomplete="off" id="RemittanceEmailAddress" class="omniipclass"
                    v-model="MediaVendor.RemittanceEmailAddress" placeholder="Enter Remittance Advice Email"
                    :maxlength="99"></b-form-input>
                    <div class="error-message" v-if="!$v.MediaVendor.RemittanceEmailAddress.email">
                    Please enter a valid email address (eg. test@FinSys.com)
                    </div>
                </div>
                <div class="mt-2">
                    <label for="axlocatoin" class="lbltxt">Please attach bank letter, invoice, or voided check.</label>
                    <vue-dropzone v-on:vdropzone-mounted="mountedBankLetterInvoice" :duplicateCheck="true" ref="bankLetterInvoiceVueDropzone" id="dropzone" :useCustomSlot="true"
                    class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                    v-on:vdropzone-file-added="UploadedBankLetterInvoicetoDropzone" v-on:vdropzone-removed-file="RemovedBankLetterInvoiceFromDropzone"
                    v-modal="test">
                    <div class="dropzone-custom-content">
                        <p class="Drag-and-drop-file-h">
                        <b-img style="
                                margin-top: -7px !important;
                                font-family: 'Open Sans';
                            " />
                        Drag and drop file here or
                        <span class="draganddropspan"> browse </span>

                        </p>
                    </div>
                    </vue-dropzone>
                </div>
            </div>

            <!-- <div class="d-block row col-lg-8 offset-lg-2" v-if="IsCreditCardNotAccepted">
            <p class="extra-message-format">If no, vendor will be paid by Check to the address provided on the W9.</p>
        </div> -->
            <div class="d-block row col-lg-8 mx-auto" v-if="IsCreditCardNotAccepted">
                <b-form-group>
                    <slot name="label">Would the vendor like to be set up by ACH Payment?</slot>
                    <custom-tooltip :content="'If this vendor is located in a US territory, ACH is required for payment.'"></custom-tooltip>
                    <b-form-radio-group id="ACHPayment" v-model="MediaVendor.ACHPayment"
                        name="ACHPayment">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="ShowBankingInfo">
                <b-form-group>
                    <slot name="label">You must obtain a bank letter from the vendor’s bank, or a voided check to setup ACH payments. Have you collected this information?</slot>
                    <b-form-radio-group id="banking-info-collection" v-model="MediaVendor.IsBankingInfoCollected"
                        name="banking-info-collected">
                        <b-form-radio value="true">Yes</b-form-radio>
                        <b-form-radio value="false">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="d-block row col-lg-8 mx-auto" v-if="ShowBankingAttachment">
                <label>Attach ACH Banking Info</label>
                <vue-dropzone v-on:vdropzone-mounted="mountedmyVueDropzone" :duplicateCheck="true" ref="myVueDropzone"
                    id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false"
                    :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedToDropzone"
                    v-on:vdropzone-removed-file="RemovedFromDropzone">
                    <div class="dropzone-custom-content">
                        <p class="Drag-and-drop-file-h">
                            <b-img :src="closeFilterIcon" style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                            Drag and drop file here or
                            <span class="draganddropspan"> browse </span>
                        </p>
                    </div>
                </vue-dropzone>
            </div>
        </div>

        <div class="form-group pt-60 text-right footer-buttons" style="padding-right: 96px !important">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                BACK
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
                NEXT
            </button>
        </div>
        <b-modal v-model="ShowPaymentInfo" hide-footer :no-close-on-backdrop="true">
            <div>
                <p>
                    If no, vendor will be paid by Check to the address provided on the Invoice.
                </p>
            </div>
            <template v-slot:modal-header="{ close }">
                <h5 class="modal-title">Note</h5>
                <button class="close" @click="CancelPaymentInfo()"></button>
            </template>

            <div class="text-right">
                <button class="text-uppercase btn custom-button btn-primary" @click="CancelPaymentInfo()">
                    Ok
                </button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { required, requiredIf, minLength, numeric, helpers } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import service from "../../../services/Dropdowns";
import { TheMask } from 'vue-the-mask';
const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)
export default {
    components: {
        vueDropzone: vue2Dropzone,TheMask
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            CountryUnitedStatesValue: "USA - United States",
            CountryList: [],
            StateList: [],
            FilesUploaded: false,
            ShowPaymentInfo: false,
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeFilterIcon: require("../../../assets/Icons/file.png"),
            dropzoneOptions: {
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
                // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert("Maximum File Upload limit is 5!");
                        }
                    });

                    this.on("removedfile", function () { });
                },
            },
        }
    },
    validations: {
        MediaVendor: {
            IsCreditCardAccepted: {
                required: required
            },
            ACHPayment: {
                required: requiredIf(function (model) {
                    return model.IsCreditCardAccepted == "false" || model.IsCreditCardAccepted == false;
                })
            },
            IsBankingInfoCollected: {
                required: requiredIf(function (model) {
                    return model.ACHPayment == "true" || model.ACHPayment == true;
                })
            },
            AreThereConditionsOrLimitations: {
                required: requiredIf(function (model) {
                    return model.IsCreditCardAccepted == "true" || model.IsCreditCardAccepted == true;
                })
            },
            CreditLimitation: {
                required: requiredIf(function (model) {
                    return model.AreThereConditionsOrLimitations == "true" || model.AreThereConditionsOrLimitations == true;
                })
            },
            HasACHPaymentSetup: {
                required: requiredIf(function (model) {
                    return model.AreThereConditionsOrLimitations == "true" || model.AreThereConditionsOrLimitations == true;
                }),
            },
            HasBankLetterInvoice: {
                required: requiredIf(function (model) {
                    return model.HasACHPaymentSetup == "true" || model.HasACHPaymentSetup == true;
                }),
            },
            RemittanceEmailAddress: {
                required: requiredIf(function (model) {
                return model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
                email: EmailWithoutCase
            },
            CreditCardFiles: {
                minLength: minLength(1),
                required: requiredIf(function () {
                    return this.MediaVendor.IsBankingInfoCollected == 'true' || this.MediaVendor.IsBankingInfoCollected == true;
                })
            },
            BankAccountName: {
                required: requiredIf(function (model) {
                return model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            BankAccountNumber: {
                required: requiredIf(function (model) {
                return model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
                numeric,
            },
            BankRoutingNumber: {
                required: requiredIf(function (model) {
                return  model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
                numeric,
            },
            BankName: {
                required: requiredIf(function (model) {
                return  model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            BankCountry: {
                required: requiredIf(function (model) {
                return  model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            BankAddressLine1: {
                required: requiredIf(function (model) {
                return  model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
            BankCity: {
                required: requiredIf(function (model) {
                    return model.BankCountry == model.CountryUnitedStatesValue &&  model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
            },
            BankState: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue &&  model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
            },
            BankZipCode: {
                required: requiredIf(function (model) {
                    return model.BankCountry == this.CountryUnitedStatesValue &&  model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                }),
                numeric,
            },
            BankLetterInvoice: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return model.HasBankLetterInvoice == "true" || model.HasBankLetterInvoice == true;
                })
            },
        }
    },
    mounted() {
        this.LoadDropdownValues();
    },
    computed: {
        HasBankLetterInvoice() {
            return this.MediaVendor.HasBankLetterInvoice == 'true' || this.MediaVendor.HasBankLetterInvoice == true;
        },
        AreThereConditionsOrLimitations() {
            return this.MediaVendor.AreThereConditionsOrLimitations == 'true' || this.MediaVendor.AreThereConditionsOrLimitations == true;
        },
        IsCountryUSA() {
            return this.MediaVendor.BankCountry != null ? this.MediaVendor.BankCountry == this.CountryUnitedStatesValue : null;
        },
        IsCreditCardAccepted() {
            return this.MediaVendor.IsCreditCardAccepted == 'true' || this.MediaVendor.IsCreditCardAccepted == true;
        },
        ShowBankingAttachment() {
            return (this.MediaVendor.IsBankingInfoCollected == 'true' || this.MediaVendor.IsBankingInfoCollected == true) && (this.MediaVendor.ACHPayment == 'true'|| this.MediaVendor.ACHPayment == true);
        },
        ShowBankingInfo() {
            return (this.MediaVendor.ACHPayment == 'true' || this.MediaVendor.ACHPayment == true) && (this.MediaVendor.IsCreditCardAccepted == 'false' || this.MediaVendor.IsCreditCardAccepted == false)
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('mediavendor', {
            MediaVendor: 'MediaVendor',
        }),
        IsCreditCardNotAccepted() {
            return this.MediaVendor.IsCreditCardAccepted == 'false' || this.MediaVendor.IsCreditCardAccepted == false;
        },
        IsBankingInfoNotCollected() {
            return this.MediaVendor.IsBankingInfoCollected == 'false' || this.MediaVendor.IsBankingInfoCollected == false;
        },
        IsErrorMessage() {
            return this.IsCreditCardNotAccepted && this.IsBankingInfoNotCollected;
        }
    },
    watch: {
        "MediaVendor.AreThereConditionsOrLimitations": function (val) {
            if (val == false || val == 'false' || !this.IsCreditCardAccepted) {
                this.MediaVendor.CreditLimitation = '';
                this.MediaVendor.HasACHPaymentSetup = null;
                this.MediaVendor.HasBankLetterInvoice = null;
            }
        },
        "MediaVendor.IsCreditCardAccepted": function (val) {
            if(val == false || val == "false") {
                this.MediaVendor.CreditLimitation = '';
                this.MediaVendor.AreThereConditionsOrLimitations = null;
                this.MediaVendor.RemittanceEmailAddress = '';
            }
            if (val == true || val == "true") {
                this.$set(this.MediaVendor, "IsBankingInfoCollected", null);
                this.$set(this.MediaVendor, "ACHPayment", null);
            } else {
                if (this.MediaVendor.ID > 0 && this.FilesUploaded == false) {
                    this.ManuallyUploadFiles(this.MediaVendor.CreditCardFiles);
                    this.$set(this.MediaVendor, "CreditCardFiles", this.GetFilesObject());
                    this.ManuallyUploadBankLetterFiles(this.MediaVendor.BankLetterInvoice);
                    this.$set(this.MediaVendor, "BankLetterInvoice", this.GetBankLetterFilesObject());
                }

            }

        },
        "MediaVendor.ACHPayment": function (val) {
            if (val == false || val == "false") {
                this.ShowPaymentInfo = true;
            }
            if (val == true || val == "true") {
                this.$set(this.MediaVendor, "IsBankingInfoCollected", null);
            }
        },
        "MediaVendor.HasACHPaymentSetup": function (val) {
            if (val == false || val == "false") {
                this.ShowPaymentInfo = true;
                this.MediaVendor.HasBankLetterInvoice = null;
            }
        },
        "MediaVendor.HasBankLetterInvoice": function (val) {
            if (val == false || val == "false" || !val) {
                this.ShowPaymentInfo = true;
                this.MediaVendor.BankName = '';
                this.MediaVendor.BankCountry = '';
                this.MediaVendor.BankAddressLine1 = '';
                this.MediaVendor.BankAddressLine2 = '';
                this.MediaVendor.BankCity = '';
                this.MediaVendor.BankState = '';
                this.MediaVendor.BankZipCode = '';
                this.MediaVendor.BankAccountName = '';
                this.MediaVendor.BankAccountNumber = '';
                this.MediaVendor.BankRoutingNumber = '';
            }
        },
        "MediaVendor.IsBankingInfoCollected": function (val, oldval) {
            if (val == false || val == "false") {
                this.ShowPaymentInfo = true;
            }
            if ((val == false || val == "false") && (oldval == true || oldval == "true")) {
                this.$set(this.MediaVendor, "CreditCardFiles", []);
            } else {
                if (this.MediaVendor.ID > 0 && this.FilesUploaded == false) {
                    this.ManuallyUploadFiles(this.MediaVendor.CreditCardFiles);
                    this.$set(this.MediaVendor, "CreditCardFiles", this.GetFilesObject());
                    this.ManuallyUploadBankLetterFiles(this.MediaVendor.BankLetterInvoice);
                    this.$set(this.MediaVendor, "BankLetterInvoice", this.GetBankLetterFilesObject());

                }
            }
        }
    },

    methods: {
        SetDirty(propertyName) {
            this.$v.MediaVendor[propertyName].$touch();

        },
        ResetDirty(propertyName) {
            this.$v.MediaVendor[propertyName].$reset();
        },
        LoadDropdownValues() {
            this.LoadStates();
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.CountryList = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.CountryList.findIndex(
                            (i) => i.label == this.CountryUnitedStatesValue
                        );
                        let indexCanada = this.CountryList.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.CountryList.unshift(
                                this.CountryList.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.CountryList.unshift(this.CountryList.splice(indexUSA, 1)[0]);
                        this.CountryList.forEach(element => { element.value = element.label;});
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.StateList = res.data;
                    this.StateList.forEach(element => { element.value = element.label;});
                })
                .catch((err) => {
                    alert(err);
                });
        },
        mountedmyVueDropzone() {
            if (this.MediaVendor.ID > 0 && this.FilesUploaded == false) {
                this.ManuallyUploadFiles(this.MediaVendor.CreditCardFiles);
                this.$set(this.MediaVendor, "CreditCardFiles", this.GetFilesObject());
            }
        },
        mountedBankLetterInvoice() {
            if (this.MediaVendor.ID > 0 && this.FilesUploaded == false) {
                this.ManuallyUploadBankLetterFiles(this.MediaVendor.BankLetterInvoice);
                this.$set(this.MediaVendor, "BankLetterInvoice", this.GetBankLetterFilesObject());
            }
        },
        CancelPaymentInfo() {
            this.ShowPaymentInfo = false;
        },
        ManuallyUploadFiles(files) {
            if (this.$refs.myVueDropzone != null) {
                if (files != null && files.length > 0 && this.FilesUploaded == false) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.myVueDropzone.manuallyAddFile(file, url);
                    });
                }
                this.FilesUploaded = true;
            }
        },
        ManuallyUploadBankLetterFiles(files) {
            if (this.$refs.bankLetterInvoiceVueDropzone != null) {
                if (files != null && files.length > 0 && this.FilesUploaded == false) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.bankLetterInvoiceVueDropzone.manuallyAddFile(file, url);
                    });
                }
                this.FilesUploaded = true;
            }
        },
        UploadedToDropzone() {
            this.$set(this.MediaVendor, "CreditCardFiles", this.GetFilesObject());
        },
        RemovedFromDropzone() {
            this.$set(this.MediaVendor, "CreditCardFiles", this.GetFilesObject());
        },
        UploadedBankLetterInvoicetoDropzone() {
            this.$set(this.MediaVendor, "BankLetterInvoice", this.GetBankLetterFilesObject());
        },
        RemovedBankLetterInvoiceFromDropzone() {
            this.$set(this.MediaVendor, "BankLetterInvoice", this.GetBankLetterFilesObject());
        },
        GetFilesObject() {
            let files = [];
            if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        GetBankLetterFilesObject() {
            let files = [];
            if (this.$refs.bankLetterInvoiceVueDropzone.dropzone.files && this.$refs.bankLetterInvoiceVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.bankLetterInvoiceVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Setup Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Setup Info"
                });
            }
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor External Attachment",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor External Attachment",
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
    }
}
</script>

<style>
fieldset.form-group:not(.custom-radio)>legend {
    padding-left: 0px !important;
}

/*file upload starts*/

.customdropzone {
    width: 411px !important;
    height: 44px !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    /* margin-left: 97px !important; */
    margin-top: 14px !important;
    width: 237px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    color: #a2a9ad !important;
}

.draganddropspan {
    color: #00a1d2;
}

/*file upload ends*/
</style>
